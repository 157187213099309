import React from "react"
import Layout from "../components/Layout"

import ReactPlayer from "react-player"

const greta = () => {
  return (
    <Layout>
      <section style={{ position: "relative" }}></section>
      <div
        style={{
          display: "flex",
          flexFlow: "row",
          backgroundColor: "#1d242a",
          padding: "40px",
        }}
      >
        <div
          style={{
            backgroundColor: "black",
            width: "50%",
            height: "700px",
            justifyContent: "center",
          }}
        >
          <ReactPlayer
            url="https://youtu.be/1y8hLocXgIw"
            playing={true}
            loop={true}
            width="100%"
            height="100%"
            light="/waldfred.png"
          />
        </div>
        <div
          style={{
            backgroundColor: "#1d242a",
            width: "50%",
            height: "700px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p
            style={{
              paddingTop: "50px",
              color: "white",
              fontFamily: "Inconsolata",
              fontSize: "25px",
              margin: "40px",
              textAlign: "center",
            }}
          >
            <h2>Waldfred- the wanderer: </h2> For the 2020 game Waldfred- der
            Wanderer for the Entertainment Computing course, I contributed to
            the character and game design. I took over parts of the programming
            and recorded the game music with musical friends to create a nice
            basic ambience.
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default greta
